import React from "react"
import SEO from "../components/seo"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import twitterIcon from "../images/twitter-icon.svg"
import linkedInIcon from "../images/linkedin-icon.svg"

export default () => (
    <div>
        <SEO title={'Welcome'} />
        <div style={{ textAlign: `center`, height: `200px`, 
                    width: `400px`, position: `fixed`, top: `50%`, left: `50%`, 
                    marginTop: `-100px`, marginLeft: `-200px` }}>
            <h1 style={{ fontSize: `80px`}}>jbryce</h1>
            <p><OutboundLink href="https://twitter.com/jbryce"><img src={twitterIcon} alt="Twitter" style={{paddingRight: '30px'}} /></OutboundLink>
            <OutboundLink href="https://www.linkedin.com/in/jbryce"><img src={linkedInIcon} alt="LinkedIn" style={{paddingLeft: '30px'}} /></OutboundLink></p>
        </div>
    </div>
)